<template>
  <div style="text-align: center" class="pb-4" v-if="!loadingStatus.isDone || loadingStatus.isError">
    <span v-show="!loadingStatus.isDone && !loadingStatus.isError">Загрузка данных...</span>
    <span v-show="loadingStatus.isError">Ошибка при загрузке данных</span>
  </div>
  <div v-else>

    <div class="col-lg-6 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Добавить агента</h4>
          </div>
          <div class="p-4 row m-0">
            <InputBox
                label="Логин"
                placeholder="Логин саппорта"
                v-model="newSupport.login"
            />
            <InputBox
                label="Пароль"
                type="password"
                placeholder="Пароль саппорта"
                v-model="newSupport.password"
            />
            <div>
              <Button
                  class="w-100 primary"
                  :callback="createNewSupport"
                  :is-disabled="newSupport.login === '' || newSupport.password === ''"
              >
                Добавить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered d-flex align-items-center justify-content-space-between">
            <h4>Команда поддержки</h4>
            <InputBox
                placeholder="Поиск агента"
                v-model="searchSupport"
                class="m-0"
            />
          </div>
          <div class="row m-0 p-3">


            <p class="text-muted pr-4 pl-4">Добавьте домен чтобы спаммер получал информацию только с него. Чтобы оставить доступ ко всем доменам, удалите все текущие домены.</p>
            <p class="text-muted pr-4 pl-4">Ограничение доменов влияет на страницы: "Главная", "Логи", "Заявки KYC", "Юзеры", "Лайв саппорт", "Тикеты".</p>

            <div class="col-lg-6 col-md-12 col-xs-12 mt-3 mb-3" v-for="support, index in filteredSupportTeam" :key="'support ' + index">
              <div class="Card border">
                <div class="Card-content">
                  <div class="Card-header bordered">
                    <h4 style="font-size: 13px;">{{ support.login }}</h4>
                  </div>
                  <div style="font-size: 13px;" class="p-4 row m-0">
                    <div class="d-flex justify-content-space-between mb-2">
                      <span>Последняя активность: </span>
                      <span>{{ support.lastActiveDate | toDate }}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-space-between">
                      <span class="mr-3">{{ support.supportMember.isActive ? 'Аккаунт активен' : 'Аккаунт выключен' }}</span>
                      <SwitchButton
                          :is-checked="support.supportMember.isActive"
                          @switch-change="support.supportMember.isActive = $event"
                      />
                    </div>
                    <div class="d-flex align-items-center justify-content-space-between">
                      <span class="mr-3">Может вкл/выкл абуз промокода</span>
                      <SwitchButton
                          :is-checked="support.permissions.canChangeAbusePromocodes"
                          @switch-change="support.permissions.canChangeAbusePromocodes = $event"
                      />
                    </div>
                    <div class="d-flex align-items-center justify-content-space-between mb-4">
                      <span class="mr-3">Может удалять/редактировать сообщения в саппорте</span>
                      <SwitchButton
                          :is-checked="support.permissions.canChangeOrDeleteSupport"
                          @switch-change="support.permissions.canChangeOrDeleteSupport = $event"
                      />
                    </div>
                    <InputBox
                        :label-none="true"
                        type="password"
                        placeholder="Введите новый пароль агента"
                        v-model="support.password"
                    />

                    <div class="mb-3">
                      <span style="font-size: 14px; display: block;" class="mb-3">Домены в работе</span>
                      <div style="flex-wrap: wrap;" class="d-flex align-items-center">
                        <div class="sup-domain bg-success ml-0 mb-2 mr-2" v-if="support.supportMember.domains.length === 0">Все домены</div>
                        <div style="color: black;" class="sup-domain bg-warning ml-0 mb-2 mr-2 align-items-center d-flex" v-for="domain, index in support.supportMember.domains" :key="'domain sup' + index + support._id">
                          {{ domain }}
                          <font-awesome-icon @click="support.supportMember.domains.splice(index, 1)" class="ml-2" style="cursor: pointer;" :icon="['fas', 'times']" />
                        </div>
                      </div>
                      <div class="form-control align-items-center col-lg-12 mb-5 p-0">
                        <select @change="changeSupportDomainsHander($event, support._id)" style="height: 38px; position: relative; top: 6px"  class="w-100">
                          <option value="-" selected disabled>Добавить домен</option>
                          <option :value="domain.domain" v-for="domain, index in fetchedData.domains" :key="'domain ' + index">
                            {{ domain.domain }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div>
                      <span style="font-size: 14px; display: block;" class="mb-3">Доступные страницы</span>
                      <div class="pr-3 pl-3">
                        <div class="d-flex align-items-center justify-content-space-between mb-2" v-for="page, index in support.permissions.pages" :key="'page' + index">
                          <span>{{ menuLinks[index] }}</span>
                          <SwitchButton
                              class="ml-4"
                              @switch-change="support.permissions.pages[index] = $event"
                              :is-checked="page"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-xs-12 p-0 mt-4">
                      <Button
                          :is-async="true"
                          :callback="() => updateSupportTeam(support._id, support)"
                          class="primary w-100"
                      >
                        Обновить
                      </Button>
                      <Button
                          :is-async="true"
                          :callback="() => deleteSupportSessions(support._id)"
                          class="primary w-100 mt-2"
                      >
                        Удалить сессии
                      </Button>
                      <Button
                          :is-async="true"
                          :callback="() => deleteSupportAccount(support._id)"
                          class="danger w-100 mt-2"
                      >
                        Удалить аккаунт
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";

export default {
  name: "Domains",
  props: {
    information: Object
  },
  components: {
    InputBox,
    Button,
    SwitchButton
  },
  data() {
    return {
      menuLinks: {
        main: "Главная страница (со статистикой)",
        users: "Юзеры",
        logs: "Логи",
        kyc: "Заявки KYC",
        deposits: "Депозиты",
        live_support: "Лайв саппорт",
        tickets: "Тикеты",
        promocodes: "Промокоды",
        mnemonics: "Мнемоник фразы",
        payouts: "Выплаты",
        statistic: "Статистика",
        settings: "Настройки",
        updates: "Обновления"
      },
      searchSupport: '',
      newSupport: {
        login: "",
        password: ""
      },
      fetchedData: {},
      loadingStatus: { isDone: false, isError: false }
    }
  },
  methods: {
    changeSupportDomainsHander(event, supportId) {
      const supportInfo = this.fetchedData.supportTeam.find(x => x._id === supportId);
      if (!supportInfo) return event.target.value = '-';
      if (supportInfo.supportMember.domains.includes(event.target.value)) return event.target.value = '-';

      supportInfo.supportMember.domains.push(event.target.value);
      event.target.value = '-';
    },
    async deleteSupportAccount(supportId) {
      try {
        const confirm = window.confirm("Вы уверены что хотите удалить аккаунт данного саппорта?");
        if (!confirm) return;

        const fetchedData = await axios.post('backoffice/user/settings/deleteSupportAccount', {
          supportId
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
        location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async deleteSupportSessions(supportId) {
      try {
        const confirm = window.confirm("Вы уверены что хотите почистить ВСЕ сессии данного спаммера/саппорта?");
        if (!confirm) return;

        const fetchedData = await axios.post('backoffice/user/settings/deleteSupportSessions', {
          supportId
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async createNewSupport() {
      try {
        const fetchedData = await axios.post('backoffice/user/settings/createNewSupport', {
          newSupport: this.newSupport
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) {
          this.newSupport = { login: "", password: "" };
          await this.loadSupportTeam();
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateSupportTeam(supportId, supportInfo) {
      try {
        const fetchedData = await axios.post('backoffice/user/settings/updateSupportTeam', {
          supportId,
          supportInfo
        });

        supportInfo.password = '';
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async loadSupportTeam() {
      try {
        this.loadingStatus.isDone = false;
        const fetchedData = await axios.get('backoffice/user/settings/supportTeam');
        if (fetchedData.data.error) return this.loadingStatus.isError = true;
        this.fetchedData = fetchedData.data;
        this.loadingStatus.isDone = true;
      } catch (e) {
        console.log(e)
        this.loadingStatus.isError = true;
      }
    }
  },
  computed: {
    filteredSupportTeam() {
      return this.fetchedData.supportTeam.filter(x => x.login.includes(this.searchSupport));
    }
  },
  mounted() {
    this.loadSupportTeam();
  }
}
</script>

<style lang="scss" scoped>

span.delete-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f06548;
    }
  }
}

.sup-domain {
  font-size: 11px;
  width: auto;
  padding: 4px 8px;
  border-radius: 4px;
}

</style>